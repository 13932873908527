import "./Imprint.css";

export function Imprint(){
    return (
        <div className={"default-text-container"}>
            <button className="ghost">
                <p>Angaben gemäß § 5 TMG</p>
            </button>
            <h1>Impressum</h1>

            <h3>Anschrift</h3>
            <p>EndTech UG (haftungsbeschränkft)<br/>Gröbenzellerstr. 32<br/>85221 Dachau</p>

            <h3>Register</h3>
            <p>Handelsregister 283132<br/>Registergericht München</p>

            <h3>Vertreten durch</h3>
            <p>Niklas Endter<br/>+49 176 32218838<br/>niklas@end-tech.com</p>
        </div>
    )
}