import {SectionTitle} from "../section-title/SectionTitle";
import "./Advantages.css";
import {useRef} from "react";
import {useAnimatedBinaryParallaxWithRef} from "../../hooks/useParallax";

const AdvantagesList = [
    {
        iconSrc: "/media/advantages/efficiency.svg",
        iconAlt: "",
        iconScale: 80,
        title: "Gesteigerte Effizienz und Produktivität",
        subtitle: "Durch die Eliminierung von Redundanzen und der Optimierung auf eigene Workflow "
    },
    {
        iconSrc: "/media/advantages/cost.svg",
        iconAlt: "",
        iconScale: 110,
        title: "Weniger Kosten",
        subtitle: "Durch die optimierte Nutzung menschlicher Kapazitäten und das Wegfallen wiederkehrenden Integrationsmaßnahmen"
    },
    {
        iconSrc: "/media/advantages/security.svg",
        iconAlt: "",
        iconScale: 90,
        title: "Erhöhte Sicherheit",
        subtitle: "Durch den gezielten Einsatz Kryptographischer Technologien und rekursiven Zugriffslimitierungen"
    },
    {
        iconSrc: "/media/advantages/happiness.svg",
        iconAlt: "",
        iconScale: 90,
        title: "Gesteigerte Kunden und Mitarbeiterzufriedenheit",
        subtitle: "Durch die direkte oder indirekte Optimierung des Interaktions-erlebnises mit Ihrer Organisation"
    },
    {
        iconSrc: "/media/advantages/competitiveness.svg",
        iconAlt: "",
        iconScale: 90,
        title: "Erhöhte Wettbewerbsfähigkeit",
        subtitle: "Als angestrebtes Ziel, der akkumulierten Effekte aller vorher genannten Effekte."
    }
]
export function Advantages(){

    const advantagesSectionTitleRef = useRef();
    useAnimatedBinaryParallaxWithRef(advantagesSectionTitleRef);

    const advantagesRef = useRef();
    useAnimatedBinaryParallaxWithRef(advantagesRef);

    return <>
        <SectionTitle ref={advantagesSectionTitleRef} className={"animated"} title={"Mit messbaren Vorteilen"} subtitle={"Immer mit der Sicherheit, dass die App mit Ihrem Unternehmen wachsen kann und so auch in Zukunft kein Kompromiss in diesen Bereichen eingegangen werden muss"}></SectionTitle>
        <div className="advantages animated" ref={advantagesRef}>
            {AdvantagesList.map((advantage, index) => <div key={index} className="card advantage" style={{transitionDelay: 0.4 + index*0.2 + "s"}}>
                <img src={advantage.iconSrc} alt={advantage.iconAlt} style={{width: `${advantage.iconScale}%`}}/>
                <div className="">
                    <h5>{advantage.title}</h5>
                    <p>{advantage.subtitle}</p>
                </div>
            </div>)}
        </div>
    </>
}