import {Link, useNavigate} from "react-router-dom";
import logo from "../../logo.svg";
import "./NavBar.css";
import {useContext, useRef} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {ImprintRoutePath} from "../../routes/ImprintRoute";
import {ParallaxContext} from "../../contexts/ParallaxContext";
import {useBinaryParallax, useParallax} from "../../hooks/useParallax";

export const HeroSectionId = "hero";
export const ServicesSectionId = "services";
export const ProjectsSectionId = "projects";
export const AboutUsSectionId = "about-us";

export const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if(!element){
        return;
    }
    element.scrollIntoView({
        behavior: "smooth"
    })
}

export function NavBar(){

    const generalCtx = useContext(GeneralContext);
    const navigate = useNavigate();

    const navRef = useRef();
    useBinaryParallax(() => 50, [], () => {
        navRef.current?.classList?.add("active")
    }, () => {
        navRef.current?.classList?.remove("active")
    })

    return (
        <nav ref={navRef} className={"animated"}>
            <div className="navigation">
                <Link to={`/`}>
                    <img src={logo} alt={"Company Logo"}/>
                    <h2>Endtech</h2>
                </Link>
                <div className="items">
                    <button className="clear item" onClick={() => scrollToSection(ServicesSectionId)}>
                        <p>Leistungen</p>
                    </button>
                    <button className="clear item" onClick={() => scrollToSection(ProjectsSectionId)}>
                        <p>Projekte</p>
                    </button>
                    <button className="clear item" onClick={() => scrollToSection(AboutUsSectionId)}>
                        <p>Über Uns</p>
                    </button>
                </div>
            </div>
            <div className="ctas">
                <button className="clear" onClick={() => navigate(ImprintRoutePath)}>
                    <p>Impressum</p>
                </button>
                <button className="primary request" onClick={() => generalCtx.setShowRequest(true)}>
                    <p>Anfragen</p>
                </button>
            </div>
        </nav>
    )
}