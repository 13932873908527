import {NavBar} from "../components/navbar/NavBar";
import {Footer} from "../components/footer/Footer";
import {Imprint} from "../components/imprint/Imprint";

export const ImprintRoutePath = "/imprint";
export function ImprintRoute(){
    return <>
        <NavBar></NavBar>
        <div className="content-container">
            <Imprint></Imprint>
            <Footer></Footer>
        </div>
    </>
}