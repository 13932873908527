import {createContext, useState} from "react";
import {RequestDialog} from "../components/request/RequestDialog";

export const GeneralContext = createContext({
    showRequest: false,
})

export function GeneralContextProvider({ children }){

    const [showRequest, setShowRequest] = useState(false);

    return (
        <GeneralContext.Provider value={{
            showRequest: showRequest,
            setShowRequest: setShowRequest
        }}>
            {children}
            {showRequest && <RequestDialog></RequestDialog>}
        </GeneralContext.Provider>
    )
}