import {useContext, useEffect, useRef} from "react";
import {ParallaxContext} from "../contexts/ParallaxContext";

export function useParallax(getRange, deps, cb){
    const parallaxEffect = useRef(undefined);
    const parallaxCtx = useContext(ParallaxContext);
    useEffect(() => {
        const range = getRange();
        if(!range){
            return;
        }
        parallaxEffect.current = parallaxCtx.registerEffect({
            range: range,
            cb: cb
        });
        return () => {
            parallaxCtx.unregisterEffect(parallaxEffect.current.id);
        }
    }, deps);
    return {effect: parallaxEffect, ctx: parallaxCtx}
}

export const BINARY_PARALLAX_BUFFER = 200;
export const BINARY_PARALLAX_INTERSECTION = window.innerWidth < 800 ? 100:150;

export function useBinaryParallax(getThreshold, deps, onReached, onNotReached, defaultReached = false){
    const parallaxEffect = useRef(undefined);
    const thresholdReached = useRef(defaultReached);
    const parallaxCtx = useContext(ParallaxContext);
    useEffect(() => {
        const threshold = getThreshold();
        if(!threshold){
            return;
        }
        parallaxEffect.current = parallaxCtx.registerEffect({
            range: {
                min: threshold-BINARY_PARALLAX_BUFFER,
                max: threshold+BINARY_PARALLAX_BUFFER
            },
            cb: (progress) => {
                if(progress < .5){
                    if(thresholdReached.current){
                        thresholdReached.current = false;
                        onNotReached(false);
                    }
                }else{
                    if(!thresholdReached.current){
                        thresholdReached.current = true;
                        onReached(true);
                    }
                }
            }
        });
        return () => {
            parallaxCtx.unregisterEffect(parallaxEffect.current.id);
        }
    }, deps);
    return {effect: parallaxEffect, ctx: parallaxCtx}
}

export function useAnimatedBinaryParallaxWithRef(ref, defaultReached = false){
    useBinaryParallax(() => {
        return ref.current?.offsetTop-window.innerHeight+BINARY_PARALLAX_INTERSECTION;
    }, [ref.current], () => {
        ref.current?.classList?.add("active")
    }, () => {
        ref.current?.classList?.remove("active")
    }, defaultReached);
}