export const LogLevel = {
    "Info": {
        logFunction: console.info
    },
    "Warning": {
        logFunction: console.warn
    },
    "Error": {
        logFunction: console.error
    },

    "Debug": {
        logFunction: console.debug
    },
}

/**
 * Currently only serves as a customizable central logging point,
 * that can easily be expanded to use more complex logging systems
 */
export class Logger{
    static info(...messages){
        this.log(LogLevel.Info, ...messages);
    }
    static warning(...messages){
        this.log(LogLevel.Warning, ...messages);
    }
    static error(...messages){
        this.log(LogLevel.Error, ...messages);
    }
    static debug(...messages){
        this.log(LogLevel.Debug, ...messages);
    }

    static log(level, ...messages){
        let logFunction = LogLevel[level]?.logFunction;
        if(!logFunction){
            logFunction = console.log;
        }
        logFunction(...messages);
    }
}