import "./SectionTitle.css";
import {forwardRef} from "react";

export const SectionTitle = forwardRef(({chip, title, subtitle, className, sectionId}, ref) => {
    return (
        <div className={`title-container ${className}`} ref={ref}>
            {chip && <button className="ghost" id={sectionId}>
                <p>{chip}</p>
            </button>}
            <h3>{title}</h3>
            <p className="subtitle">{subtitle}</p>
        </div>
    )
})