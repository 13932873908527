export function Project({ project, style }){

    const openProject = () => {
        window.open(project.link, "_blank");
    }

    return <div className={`card project ${project.className}`} style={style}>
        <img src={project.imgSrc} alt={project.imgAlt}/>
        <div className="information">
            <h5>{project.title}</h5>
            <p>{project.subtitle}</p>
            <button className="primary green round" onClick={openProject}>
                <p>Jetzt Ausprobieren</p>
            </button>
        </div>
    </div>
}