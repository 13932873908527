import "./Schema.css";
import {Fragment, useRef, useState} from "react";
import {useAnimatedBinaryParallaxWithRef} from "../../hooks/useParallax";

export const SchemaStepList = [
    {
        title: "Anfrage",
    },
    {
        title: "Anforderungsanalyse",
    },
    {
        title: "Angebot",
        subtitle: ""
    },
    {
        title: "Konzeptionsphase"
    },
    {
        title: "Entwicklngsphase"
    },
    {
        title: "Übergabe"
    }
]

export function Schema(){

    const [expanded, setExpanded] = useState(false);

    const schemaRef = useRef();
    useAnimatedBinaryParallaxWithRef(schemaRef);

    return (
        <div className={"schema animated"} ref={schemaRef}>
            <div className="title-container left">
                <h3>Mehr als nur Entwicklung</h3>
                <p className="subtitle"><span>Ob fertiges Konzept oder erste Idee</span>, wir unterstützen Sie auf dem gesamten Weg von Relevanz und Nutzungsprüfung über Konzeption und Design bis zur technischen Umsetzung</p>
            </div>
            <div className={`steps ${expanded ? "expanded":""}`} onClick={() => setExpanded(!expanded)}>
                {SchemaStepList.map((step, index) => <Fragment key={index}>
                    <div className="step" style={{transitionDelay: 0.4 + index*0.2 + "s"}}>
                        <div className="number">
                            <p>{index+1}</p>
                        </div>
                        <div className="container">
                            <h5>{step.title}</h5>
                        </div>
                    </div>
                    {index !== SchemaStepList.length-1 && <div className={"connector"} style={{transitionDelay: 0.4 + index*0.2 + 0.1 + "s"}}></div>}
                </Fragment>)}
            </div>
        </div>
    )
}