import "./RequestDialog.css";
import {useContext, useEffect, useState} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {useForm} from "react-hook-form";

export function RequestDialog(){

    const generalCtx = useContext(GeneralContext);
    const [active, setActive] = useState(false);
    useEffect(() => {
        setActive(true);
    }, []);

    const close = () => {
        generalCtx.setShowRequest(false);
    }

    const {register, formState, handleSubmit} = useForm();
    const [sent, setSent] = useState(false);

    const onSubmit = async (data) => {
        const res = await fetch(process.env["REACT_APP_API_URL"] + "/api/requests", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
        })
        if(res.status === 201){
            setSent(true);
        }
    }

    return (
        <div className={`request-dialog ${active ? "active":""}`}>
            <div className="background" onClick={close}></div>
            <div className="content-container">
                <div className="container">
                    {sent && <div className={"sent"}>
                        <h3>Vielen Dank für Ihr Interesse!</h3>
                        <p><span>Wir werden Ihre Anfrag innerhalb der nächsten 2 Tage bearbeiten und uns via E-Mail bei Ihnen melden.</span> In der Zwischenzeit sind Sie herzlich dazu eingeladen unseren Demo-Projekte zu erkunden.</p>
                        <button className="primary" onClick={close}>
                            <p>Fertig</p>
                        </button>
                    </div>}
                    {!sent && <form action="#" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <input {...register("firstName", {required: true})} type="text" placeholder={"Vorname..."}/>
                            <input {...register("lastName", {required: true})} type="text" placeholder={"Nachname..."}/>
                        </div>
                        <input {...register("email", {required: true})} type="email" placeholder={"E-Mail..."}/>
                        <textarea {...register("message", {required: true})} placeholder={"Nachricht..."}></textarea>

                        <button className={`primary ${(formState.isSubmitting || sent) ? "submitting" : ""}`}
                                disabled={!formState.isValid} type={"submit"}>
                            <p>{sent ? "Gesendet!" : formState.isSubmitting ? "Wird gesendet..." : "Senden"}</p>
                        </button>
                    </form>}
                </div>
            </div>
        </div>
    )
}