import {NavBar} from "../components/navbar/NavBar";
import {Footer} from "../components/footer/Footer";
import {PrivacyPolicies} from "../components/privacy-policies/PrivacyPolicies";

export const PrivacyPoliciesRoutePath = "/privacy-policies";
export function PrivacyPoliciesRoute(){
    return <>
        <NavBar></NavBar>
        <div className="content-container">
            <PrivacyPolicies></PrivacyPolicies>
            <Footer></Footer>
        </div>
    </>
}