import {SectionTitle} from "../section-title/SectionTitle";
import "./Projects.css";
import {Project} from "./project/Project";
import {ContentDivider} from "../content-divider/ContentDivider";
import {ProjectsSectionId} from "../navbar/NavBar";
import {useRef} from "react";
import {useAnimatedBinaryParallaxWithRef} from "../../hooks/useParallax";

export const TradehubProject = {
    imgSrc: "/media/projects/stocks.svg",
    imgAlt: "",
    title: "Trading Simulator",
    subtitle: "Für diese Demonstration haben wir über 50M Datenpunkte über verschiedenste Aktienkurse gesammelt und eine Applikation gebaut die diese im Millisekunden Bereich durchsuchen, verarbeiten und visualisieren kann",
    technologies: [
        {

        }
    ],
    link: "https://tradehub.end-tech.com"
}
export const WatchedProject = {
    imgSrc: "/media/projects/theater.svg",
    imgAlt: "",
    title: "Show Radar",
    subtitle: "Für diese Demonstration haben wir Informationen über mehr als 10.000 Serien gesammelt und mit Hilfe linguistischer Datenverarbeitung so indexiert, dass sie mit natürlicher Sprache in Millisekunden durchsucht werden können.",
    technologies: [
        {

        }
    ],
    className: "left",
    link: "https://showradar.end-tech.com"
}

export function Projects(){

    const titleContainerRef = useRef();
    useAnimatedBinaryParallaxWithRef(titleContainerRef);

    const projectsRef = useRef();
    useAnimatedBinaryParallaxWithRef(projectsRef);

    return <>
        <SectionTitle ref={titleContainerRef} className={"green animated"} chip={"Projekte"} sectionId={ProjectsSectionId} title={"Machen Sie sich selbst ein Bild"} subtitle={"Für uns war es besonders wichtig, dass wirklich jeder einmal eine unserer Apps verwenden kann, um sich selbst ein Bild unserer Qualität zu machen, weshalb wir uns dafür entschieden haben, öffentlich zugängliche, Demo-Projekte zu entwickeln, die genau das erlauben sollen."}></SectionTitle>
        <div ref={projectsRef} className="projects animated">
            <Project project={TradehubProject} style={{transitionDelay: 0.2 + "s"}}></Project>
            <Project project={WatchedProject} style={{transitionDelay: 0.4 + "s"}}></Project>
        </div>
    </>
}