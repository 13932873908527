import "./ServiceCard.css";

export function ServiceCard({ chip, iconSrc, iconAlt, title, description }){
    return (
        <div className={"card service-card"}>
            <img src={iconSrc} alt={iconAlt}/>
            {chip && <button className={"ghost"}>{chip}</button>}
            <h4>{title}</h4>
            <p>{description}</p>
        </div>
    )
}