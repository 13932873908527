import {createContext, useEffect, useRef, useState} from "react";
import {Logger} from "../lib/Logger";
import {v4} from "uuid";
import {useLocation} from "react-router-dom";

/**
 * The Parallax Effects will be applied as follows:
 *
 * Effects have ranges and a callback function which gets invoked with a progress percentage, if scrollY is in that range.
 * For the most efficient determination of range containment, the page is divided into intervals each containing the effects,
 * that should be called in its section.
 * This allows for O(n) + interval-iteration gathering of affected effects.
 *
 * The system is also expanded to recall each effect, that could be out of sync, due to too fast scrolling, reloading, etc.
 */

const INTERVAL_SIZE = 200;

export const ParallaxContext = createContext(undefined);

/**
 * Parallax effects are registered here with callback functions and scroll-ranges.
 * This design allows for a singular event listener and optimization potential in terms of effect indexing, etc.
 */
export function ParallaxContextProvider({ children }){

    const location = useLocation();

    // Effect Registration
    const effectRegistry = useRef({});

    const registerEffect = (effect) => {
        if(!effect.range || !effect.cb){
            return;
        }
        effect = {
            ...effect,
            id: v4(),
        }
        effectRegistry.current[effect.id] = effect;

        if(_checkIndex()){
            _indexEffect(effect);
        }

        return effect;
    }

    const unregisterEffect = (id) => {
        if(_checkIndex()){
            _unIndexEffect(effectRegistry.current[id]);
        }
        delete effectRegistry.current[id];
    }

    // Effect Indexing
    const effectIndex = useRef({});

    const _rebuildIndex = () => {
        /*
         An interval starts at its array entry and ends at the next entry's start
         The array must always be sorted ascending by value
         */
        effectIndex.current.intervals = [];
        effectIndex.current.intervalEffects = {};

        const intervalAmount = Math.ceil(document.body.scrollHeight / INTERVAL_SIZE);
        for(let i = 0; i < intervalAmount; i++){
            const interval = INTERVAL_SIZE*i;
            effectIndex.current.intervals.push(interval);
            effectIndex.current.intervalEffects[interval] = []
        }

        for(const effect of Object.values(effectRegistry.current)){
            _indexEffect(effect);
            effect.cb(_getProgress(effect))
        }
    }

    const _indexEffect = (effect) => {
        const minInterval = _minIntervalForY(effect.range.min);
        const maxInterval = _maxIntervalForY(effect.range.max);

        for(let i = minInterval; i < maxInterval; i++){
            effectIndex.current.intervalEffects[effectIndex.current.intervals[i]]?.push(effect.id);
        }
    }

    const _unIndexEffect = (effect) => {
        const minInterval = _minIntervalForY(effect.range.min);
        const maxInterval = _maxIntervalForY(effect.range.max);

        for(let i = minInterval; i < maxInterval; i++){
            const index = effectIndex.current.intervalEffects[effectIndex.current.intervals[i]]?.indexOf(effect.id);
            if (index > -1) {
                effectIndex.current.intervalEffects[effectIndex.current.intervals[i]]?.splice(index, 1);
            }
        }
    }

    const _checkIndex = () => {
        return effectIndex.current.intervals && effectIndex.current.intervalEffects;
    }

    // Utils
    const _minIntervalForY = (y) => {
        const minRemainder = y % INTERVAL_SIZE;
        return (y - minRemainder) / INTERVAL_SIZE;
    }
    const _maxIntervalForY = (y) => {
        const maxRemainder = INTERVAL_SIZE - (y % INTERVAL_SIZE);
        return (y + maxRemainder) / INTERVAL_SIZE;
    }
    const _getProgress = (effect) => {
        const rawProgress = (window.scrollY - effect.range.min) / (effect.range.max - effect.range.min);
        return Math.min(1, Math.max(rawProgress, 0));
    }


    // Scroll Handling
    useEffect(() => {
        document.addEventListener("scroll", handleScroll);
        _rebuildIndex();
        return () => {
            effectIndex.current.intervals = undefined;
            effectIndex.current.intervalEffects = undefined;
            document.removeEventListener("scroll", handleScroll);
        }
    }, [location.pathname]);

    const previousY = useRef(0);
    const handleScroll = () => {
        if(!_checkIndex()){
            return;
        }

        const minInterval = _minIntervalForY(previousY.current);
        const maxInterval = _maxIntervalForY(window.scrollY);

        const effectIds = new Set();
        for(let i = minInterval; i < maxInterval; i++){
            for(let id of effectIndex.current.intervalEffects[effectIndex.current.intervals[i]]){
                effectIds.add(id);
            }
        }

        for(let id of effectIds){
            const effect = effectRegistry.current[id];
            if(!effect){
                continue;
            }
            effect.cb(_getProgress(effect))
        }

        previousY.current = window.scrollY;
    }

    return (
        <ParallaxContext.Provider value={{
            registerEffect: registerEffect,
            unregisterEffect: unregisterEffect
        }}>
            {children}
        </ParallaxContext.Provider>
    )
}