import "./Services.css";
import {ServiceCard} from "./service-card/ServiceCard";
import {ContentDivider} from "../content-divider/ContentDivider";
import {SectionTitle} from "../section-title/SectionTitle";
import {ServicesSectionId} from "../navbar/NavBar";
import {useEffect, useRef, useState} from "react";
import {set} from "react-hook-form";
import {
    BINARY_PARALLAX_INTERSECTION,
    useAnimatedBinaryParallaxWithRef,
    useBinaryParallax,
    useParallax
} from "../../hooks/useParallax";

export function Services(){

    const servicesTitleRef = useRef();
    useAnimatedBinaryParallaxWithRef(servicesTitleRef, true);
    // useEffect(() => {
    //     if(!servicesTitleRef.current){
    //         return;
    //     }
    //     setTimeout(() => {
    //         servicesTitleRef.current?.classList?.add("active");
    //     }, 1000)
    // }, [servicesTitleRef.current])

    const servicesTopRef = useRef();
    useAnimatedBinaryParallaxWithRef(servicesTopRef);

    const optionallyWithRef = useRef();
    useAnimatedBinaryParallaxWithRef(optionallyWithRef);

    const servicesBottomRef = useRef();
    useAnimatedBinaryParallaxWithRef(servicesBottomRef);

    return <>

        <SectionTitle ref={servicesTitleRef} className={"animated"} chip={"Leistungen"} sectionId={ServicesSectionId} title={"Optimale Unterstützung durch Eigenentwicklung"} subtitle={"Eine optimale Kunden und interne Workflow-Unterstützung, mit den damit verbundenen, langhaltenden kompetetiven Vorteilen, kann nur eine Eigenentwicklung bieten und dies, durch das Wegfallen einer Prozessumstellung oder anderweitiger Integrationen, teilweise schneller als Standard-Software"}></SectionTitle>

        <div className={"services top animated"} ref={servicesTopRef}>
            <ServiceCard iconSrc={"/media/services/apis.svg"}
                         iconAlt={"Women before a world map and charts"}
                         title={"Interne Tools"}
                         description={"Helfen Sie Ihren Mitarbeitern Informationen und Daten schneller zu finden, besser zu verstehen und effizienter zu verwalten. Kontrollieren Sie Zugang und Distribution gezielt, um wieder volle Kontrolle zu haben."}></ServiceCard>
            <ServiceCard iconSrc={"/media/services/apps.svg"}
                         iconAlt={"A Mac, iPad and iPhone floating"}
                         title={"Kunden Portale"}
                         description={"Binden Sie Kunden schneller mit einer ansprechenden, intuitiven und zuverlässigen App, die die Interaktion mir Ihrem Unternehmen oder Produkt so einfach und angenehm wie möglich gestaltet und Sie so von der Konkurrenz abhebt."}></ServiceCard>
            <ServiceCard chip={"Neu!"}
                         iconSrc={"/media/services/automations.svg"}
                         iconAlt={"A man programming a brain"}
                         title={"Workflow Automation"}
                         description={"Entlasten Sie sich selbst, Mitarbeiter und Kunden mit Software die genau Ihre Worflows unterstützt oder sogar komplett automatisiert und von den unbegrenzten Möglichkeiten modernen Schnittstellen Gebrauch macht."}></ServiceCard>
        </div>
        <ContentDivider ref={optionallyWithRef} className={"text-divider optionally-with wider animated"}>
            <p>Optional auch mit langfristiger Unterstützung</p>
        </ContentDivider>
        <div className="services bottom animated" ref={servicesBottomRef}>
            <ServiceCard iconSrc={"/media/services/hosting.svg"}
                         iconAlt={"A woman protecting a server"}
                         title={"Hosting und Wartung"}
                         description={"Wir kümmern uns um das Hosting in der Cloud oder on-Prem, sowie die Wartung, der Software und Infrastruktur. CI/CD sowie Monitoring und Notfallsupport sind auch möglich."}></ServiceCard>
            <ServiceCard iconSrc={"/media/services/cd.svg"}
                         iconAlt={"Two women sitting and working"}
                         title={"Stetige Weiterentwicklung"}
                         description={"Gemeinsam legen wir ein monatliches Budget fest, in dessen Rahmen wir an Ihrer App weiterarbeiten und zum Beispiel Funktionen hinzufügen oder Performance optimieren."}></ServiceCard>
        </div>
    </>
}