import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {HomeRoute, HomeRoutePath} from "./routes/HomeRoute";
import {GeneralContextProvider} from "./contexts/GeneralContext";
import {ImprintRoute, ImprintRoutePath} from "./routes/ImprintRoute";
import {PrivacyPoliciesRoute, PrivacyPoliciesRoutePath} from "./routes/PrivacyPoliciesRoute";
import {ParallaxContextProvider} from "./contexts/ParallaxContext";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <GeneralContextProvider>
                    <ParallaxContextProvider>
                        <Routes>
                            <Route path={HomeRoutePath} element={<HomeRoute/>}></Route>
                            <Route path={ImprintRoutePath} element={<ImprintRoute/>}></Route>
                            <Route path={PrivacyPoliciesRoutePath} element={<PrivacyPoliciesRoute/>}></Route>
                        </Routes>
                    </ParallaxContextProvider>
                </GeneralContextProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;