import "./EmployeeCarousel.css";
import {useRef} from "react";
import {useAnimatedBinaryParallaxWithRef} from "../../../hooks/useParallax";

export const EmployeeList = [
    {
        imgSrc: "media/employees/ne.jpg",
        imgAlt: "",
        name: "Niklas Endter",
        quote: "“Unser Ziel ist es, jedem den positiven Einfluss, den die richtige Software für ein Unternehmen haben kann, zu zeigen und Interessierte so offen und transparent wie möglich auf diesem Weg zu begleiten.”",
        mail: "niklas@end-tech.com"
    }
]

export function EmployeeCarousel(){

    const contactEmployee = (employee) => {
        window.open(`mailto:${employee.mail}`)
    }

    const employeesRef = useRef();
    useAnimatedBinaryParallaxWithRef(employeesRef);

    return <div className={"employee-carousel animated"} ref={employeesRef}>
        <div className="employee-placeholder"></div>
        <div className="employee-placeholder"></div>
        {EmployeeList.map((employee, index) => <div key={index} className={"employee"}>
            <img src={employee.imgSrc} alt={employee.imgAlt}/>
            <h5>{employee.name}</h5>
            <p>{employee.quote}</p>
            <button className="primary blue round" onClick={() => contactEmployee(employee)}>
                <p>Direkt Kontaktieren</p>
            </button>
        </div>)}
        <div className="employee-placeholder"></div>
        <div className="employee-placeholder"></div>
    </div>
}