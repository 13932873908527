import {SectionTitle} from "../section-title/SectionTitle";
import "./AboutUs.css";
import {AboutUsSectionId} from "../navbar/NavBar";
import {ContentDivider} from "../content-divider/ContentDivider";
import {EmployeeCarousel} from "./employee-carousel/EmployeeCarousel";
import {SkillBubbles} from "./skill-bubbles/SkillBubbles";
import {useRef} from "react";
import {useAnimatedBinaryParallaxWithRef} from "../../hooks/useParallax";

export function AboutUs(){

    const titleContainerRef = useRef();
    useAnimatedBinaryParallaxWithRef(titleContainerRef);

    return <>
        <SectionTitle ref={titleContainerRef} className={"animated blue"} chip={"Über Uns"} sectionId={AboutUsSectionId} title={"Aus München, Für Alle"} subtitle={"Wir sind ein dynamisches Team erfahrener Entwickler, aus dem Enterprise Umfeld, die es sich zur Aufgabe gemacht haben, jedem die Macht von Software näher zu bringen"}></SectionTitle>
        <EmployeeCarousel></EmployeeCarousel>
        {/*<ContentDivider className={"text-divider optionally-with"}>*/}
        {/*    <p>Mit Erfahrung in</p>*/}
        {/*</ContentDivider>*/}
        {/*<SkillBubbles numBubbles={15} distanceRange={{min: 0, max: 50}} sizeRange={{min: 75, max: 250}}></SkillBubbles>*/}
    </>
}