import {NavBar} from "../components/navbar/NavBar";
import {Hero} from "../components/hero/Hero";
import {Services} from "../components/services/Services";
import {Schema} from "../components/schema/Schema";
import {Advantages} from "../components/advantages/Advantages";
import {Projects} from "../components/projects/Projects";
import {AboutUs} from "../components/about-us/AboutUs";
import {Footer} from "../components/footer/Footer";

export const HomeRoutePath = "";
export function HomeRoute(){
    return <>
        <NavBar></NavBar>
        <div className="content-container">
            <Hero></Hero>
            <Services></Services>
            <Schema></Schema>
            <Advantages></Advantages>
            <Projects></Projects>
            <AboutUs></AboutUs>
            <Footer></Footer>
        </div>
    </>
}